import React from 'react';
import styled, { css } from 'styled-components';
import Mail from '$assets/icons/mail.svg';
import Smartphone from '$assets/icons/smartphone.svg';
import { ContactItemProps } from './types';

const StyledContactItem = styled.li`
  display: inline-flex;
  align-content: center;
  white-space: nowrap;
  margin-right: 2em;
  margin-bottom: 1em;
`;

const ContactString = styled.a`
  font-family: var(--font-osynlig);
  font-feature-settings: 'ss01' on;
  font-weight: normal;
  line-height: 1.25rem;
  flex: 1 1 auto;
  color: var(--color-slate);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const StyledIcon = css`
  fill: transparent;
  stroke: currentColor;
  width: 1.25em;
  height: 1.25em;
  flex: 0 0 1.25em;
  margin-right: 0.625em;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const StyledMail = styled(Mail)`
  ${StyledIcon};
`;

const StyledSmartphone = styled(Smartphone)`
  ${StyledIcon};
`;

const ContactItem: React.FC<ContactItemProps> = ({ phone, email }) => {
  if (phone) {
    return (
      <StyledContactItem>
        <StyledSmartphone />
        <ContactString href={`tel:${phone}`}>{phone}</ContactString>
      </StyledContactItem>
    );
  }

  if (email) {
    return (
      <StyledContactItem>
        <StyledMail />
        <ContactString href={`mailto:${email}`}>{email}</ContactString>
      </StyledContactItem>
    );
  }
  return null;
};

export default ContactItem;
