import React from 'react';
import styled from 'styled-components';
import { WrapXs } from '$components/Wraps';
import Paragraph from '$components/Paragraph';
import ContactImage from '$components/ContactImage';
import Item from './Item';
import ImageWrap from './ImageWrap';
import Title from './Title';
import Contact from './Contact';
import ContactItem from './ContactItem';
import Content from './Content';
import { CaseEndCoworker } from './types';

const Outer = styled.footer`
  padding: 2.625em 0 1.5em;
  border-top: 1px solid var(--color-cloud);
  margin-top: 2.625em;
  margin-bottom: 5em;
`;

const Inner = styled.div`
  display: grid;
  grid-gap: 3.5em;

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(31.875em, 1fr));
    grid-gap: 6.111111111vw;
  }
`;

const BlockCaseEnd: React.FC<CaseEndCoworker> = ({ image, name, role, phone, email }) => (
  <WrapXs>
    <Outer>
      <Inner>
        <Item key="coworker">
          <ImageWrap>
            <ContactImage align image={{ ...image }} />
          </ImageWrap>
          <Title>Vill du veta mer?</Title>
          <Content>
            <Paragraph>
              Prata med {name}, {role}
            </Paragraph>
          </Content>
          <Contact>
            <ContactItem phone={phone} />
            <ContactItem email={email} />
          </Contact>
        </Item>
      </Inner>
    </Outer>
  </WrapXs>
);

export default BlockCaseEnd;
