import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h5`
  color: var(--color-slate);
  font-size: 1.5em;
  line-height: 2rem;
  letter-spacing: -0.02em;
  grid-area: title;
  margin: 0 0 1em;

  @media screen and (min-width: 640px) {
    font-size: 1.25em;
    margin-bottom: 0.5rem;
  }
`;

const Title: React.FC = ({ children }) => <StyledTitle>{children}</StyledTitle>;

export default Title;
