import React from 'react';
import styled from 'styled-components';

const StyledImageWrap = styled.div`
  width: 20vw;
  height: 20vw;
  grid-area: image;

  @media screen and (min-width: 640px) {
    grid-row: image-start / image-end;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  img {
    width: 100%;
    border-radius: 90em;
  }
`;

const ImageWrap: React.FC = ({ children }) => <StyledImageWrap>{children}</StyledImageWrap>;

export default ImageWrap;
