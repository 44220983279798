import React from 'react';
import styled from 'styled-components';

const StyledContact = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  grid-area: contact;
`;

const Contact: React.FC = ({ children }) => <StyledContact>{children}</StyledContact>;

export default Contact;
