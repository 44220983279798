import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
  grid-column: content-start / content-end;
  grid-area: content;

  @media screen and (min-width: 640px) {
    max-width: 68.076923077%;
  }
`;

const Content: React.FC = ({ children }) => <StyledContent>{children}</StyledContent>;

export default Content;
