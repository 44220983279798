import React from 'react';
import styled from 'styled-components';

const StyledItem = styled.div`
  color: var(--color-slate);
  display: grid;
  min-height: 100px;
  grid-template-areas:
    'title title title'
    'image content content'
    'image contact contact';
  grid-template-columns: 20vw auto auto;
  grid-gap: 0 1.5em;
  align-items: center;

  @media screen and (min-width: 640px) {
    grid-template-areas:
      'image .'
      'image title'
      'image content'
      'image contact'
      'image .';
    grid-template-columns: 8em 356fr;
    grid-gap: 0 2rem;
  }
`;

const Item: React.FC = ({ children }) => <StyledItem>{children}</StyledItem>;

export default Item;
